import React, {
   useState,
 } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const { loginWithRedirect, login } = useAuth0();

  return (
    <div>
    {
      <h1>LOGIN PAGE!!!!!</h1>
    }
    </div>
  )
}

export default Login;
