import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from 'react'
import stringWidth from "string-width"
import './flexibleText.scss'

const FlexibleText = (props) => {
  const ref = useRef()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(100)
  const [fontSize, setFontSize] = useState("auto")
  useEffect(() => {
    // const sizePx = (width / stringWidth(props.children)) * 2 - 1
    let sizePx
    const sw = stringWidth(props.children)
    // console.log(ref.current.height)
    // const max_h = width / 4
    const max_h = height
    // test
    if (sw < 20) {
      sizePx = (width / sw) * 1.7 - 1
      if (sizePx >= max_h) {
        sizePx = max_h * 0.95
      }
    } else if(sw < 30){
      sizePx = (width / sw) * 4 - 1
    } else if(sw < 40){
      sizePx = (width / sw) * 3 - 1
    }
    // else if(sw <= 5){
    //   sizePx = (width / sw) * 2 * (5 / sw) - 1
    // }
    setFontSize(`${sizePx}px`)
  }, [width, props.children])

  useLayoutEffect(() => {
    // @ts-ignore
    const obs = new ResizeObserver((e) => {
      setWidth(e[0].contentRect.width)
      setHeight(e[0].contentRect.height)
      // setHeight(e[0].contentRect.height)
      // console.log('resize!!!!!!!!!!')
      // console.log('width : ' + e[0].contentRect.width)
      // console.log('height : ' + e[0].contentRect.height)
    })
    obs.observe(ref.current)
    return () => obs.disconnect()

  }, [])

  return (
      <p
        className='flexibleText-contents'
        style={{fontSize : fontSize}}
        ref={ref}
      >
        {props.children}
      </p>
  )
}

export default FlexibleText;
