import React from 'react'

import './title.scss'

const Title = () => {
  return (
    <h1 className='title-test neumo'>i</h1>
  )
}

export default Title
