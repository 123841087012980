import React, {
  useState,
  useEffect,
} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import ReactLoading from 'react-loading'

import './loginState.scss'

import Separator    from '../00_atoms/separator/separator'

import LoginButton  from 'util/login/loginButton'
import LogoutButton from 'util/login/logoutButton'
// import Loading      from 'util/loading'


const LoginState = () => {
  const [isLogin, setIsLogin] = useState(false)
  const { isAuthenticated, isLoading } = useAuth0();

  if ( isLoading ) {
    return (
      <div className='loginState-wrapper'>
        <ReactLoading type="spinningBubbles"/>
      </div>
    )
  } else {
    return (
      <div className='loginState-wrapper'>
        {isAuthenticated ? <Contents/> : <LoginButton/> }
      </div>
    )
  }
}

const Contents = () => {
  const [isDisp, setIsDisp] = useState(false)

  const { user } = useAuth0();

  const handleClick = () => {
    setIsDisp(!isDisp)
  }

  return (
    <div className='contents-wrapper'>
      <img
        src={user.picture}
        onClick={handleClick}
      />
      {isDisp ? <DropDown /> : null}
    </div>
  )
}

const DropDown = () => {
  return (
    <div className={'dropDown-wrapper'}>
      <a>Profile</a>
      <a>aaaaaaaa</a>
      <a>Setting</a>
      <Separator/>
      <LogoutButton />
    </div>
  )
}

export default LoginState;
