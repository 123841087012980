import React from 'react';
import {Link} from 'react-router-dom';
import Popup from '../../00_atoms/popup/popup'
import './headerPopup.scss'

const HeaderPopup = (props) => {
  return (
    <Popup
      isShown      = {props.isShown}
      popupHandler = {props.popupHandler}
    >
      <div className={'headerPopup-wrapper'}>
        <div id={'header-item'} onClick={props.popupHandler}>
          <Link to="/">home</Link>
        </div>
        <div id={'header-item'} onClick={props.popupHandler}>
          <Link to="/edit">edit</Link>
        </div>
        <div id={'header-item'} onClick={props.popupHandler}>
          <Link to="/about">about</Link>
        </div>
        <div id={'header-item'} onClick={props.popupHandler}>
          <Link to="/test">test</Link>
        </div>
      </div>
    </Popup>

  )
}

export default HeaderPopup;
