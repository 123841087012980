import React, { useEffect, useState, useRef, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { UserContext } from 'app'
import * as u        from 'util/util'
import I_search from 'util/icons/i_search'
import I_reset  from 'util/icons/i_reset'
import I_shrink from 'util/icons/i_shrink'
import I_rgb    from 'util/icons/i_rgb'
import Loading  from 'util/00_atoms/loading/loading'
import './edit.scss'

import ExploreFrame from './exploreFrame/exploreFrame'

const EDIT_PAGE_RESOLUTION = 100

// var : use image on 'edit' page
const Edit = () => {
  const [f_params, setF_param]      = useState({})
  const [imageInfo, setImageInfo]   = useState(null)
  // const [gImgBase64, setGImgBase64] = useState('')
  const [isLoading, setIsLoading]   = useState(false)
  // const [imageInfo, setImageInfo] = useState('a')
  const [userTmp  , setUserTmp]     = useState({})
  const operaterID = useContext(UserContext)


  const navigate = useNavigate()
  const location = useLocation();
  const prevState = location.state;

  const bottonToColor = () => {
    // navigate('/color', { state: {f_params: f_params} })
    let ii_tmp = {...imageInfo}

    ii_tmp.f_params = f_params
    navigate('/color', {
      state: {
        // f_params  : f_params,
        imageInfo : ii_tmp,
        from      : 'edit'
      }
    })
  }

  const firstRenderFunc = async (user_id, session_id) => {
    console.log(isLoading)

    setIsLoading(true)
    const res_new = await Axios.get(u.SERVER_URL + '/imageInfo' + '?nw=true')
    let ii_new = {...res_new.data[0]}

    if(prevState === null) {
      ii_new.f_params      = u.DEFAULT_JS_F_PARAMS
      ii_new.parent_id     = ''
      ii_new.colorBarData  = u.DEFAULT_COLOR_BAR_DATA
      ii_new.operateType   = ''
      ii_new.status        = 'from_None'
    } else {
      ii_new = {...prevState.imageInfo}
    }

    ii_new.f_params.resolution = EDIT_PAGE_RESOLUTION

    setImageInfo(ii_new)
    setF_param(ii_new.f_params)
    setIsLoading(false)
  }

  const sendCulculate = async (inputFparams, inputUserId, session_id) => {
    let resdata

    setIsLoading(true)
    let userTmpUrl = u.SERVER_URL + '/userTmp?'
        userTmpUrl += 'user=' + inputUserId + '&'
        userTmpUrl += 'session=' + session_id

    // const res1 = await Axios.get(userTmpUrl)
    // let ut1 = {...res1.data}

    const ut_tmp = {...userTmp}

    ut_tmp.imageInfo.f_params = inputFparams

    resdata = await Axios.put(userTmpUrl, ut_tmp)
    setUserTmp(resdata.data)
    // setImageInfo(resdata.data.imageInfo.image_id)
    setF_param({...resdata.data.imageInfo.f_params})
    setIsLoading(false)
  }

  const handleClick = async (e) => {
    console.log('clicked image')
    let dispHeight = e.target.offsetHeight

    let p_clicked, p_before, p_new = { x : 0, y : 0 }
    let t = 1.2;

    // copy 'Object'
    let tmpF_param = {...f_params}

    // culculate 'Amount to move' [px]
    // create clicked point
    p_clicked = {
      x: e.pageX - e.target.offsetLeft,
      y: dispHeight - ( e.pageY - e.target.offsetTop )
    }

    // create before center point
    if(dispHeight !== 0){
      p_before = {
        x: dispHeight / 2,
        y: dispHeight / 2,
      }
    }

    // create new center point
    p_new.x = (1 - 1/t) * (p_clicked.x - p_before.x)
    p_new.y = (1 - 1/t) * (p_clicked.y - p_before.y)

    // Calculate the actual distance
    // coefficient　'px => real distance'
    let coef = 1 / tmpF_param.maguni / dispHeight

    tmpF_param.w = tmpF_param.w + p_new.x * coef
    tmpF_param.h = tmpF_param.h + p_new.y * coef
    // tmpF_param.maguni = Math.floor(tmpF_param.maguni * t)
    tmpF_param.maguni = tmpF_param.maguni * t

    tmpF_param.gray_id = ''

    setF_param(tmpF_param)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    sendCulculate(f_params, operaterID, 'sessionidtest')
  }

  const handleShrink = (e) => {
    let f_paramsTmp = {...f_params}
    let id = e.currentTarget.id
    // let ii_new = {...imageInfo}

    if (id === 'shrink') {
      f_paramsTmp.maguni = f_paramsTmp.maguni / 1.2
    } else if (id === 'reset') {
      if(window.confirm('Are you sure change to default value?')){
        f_paramsTmp = u.DEFAULT_JS_F_PARAMS
        // ii_new.parent_id = ''
      } else {
        return
      }
    }
    f_paramsTmp.gray_id = ''
    // ii_new.f_params = f_paramsTmp
    setF_param(f_paramsTmp)
    // setImageInfo(ii_new)

    // sendCulculate(f_paramsTmp, operaterID, 'sessionidtest')
  }

  useEffect(() => {
    // firstRenderFunc(operaterID, 'sessionidtest')
    firstRenderFunc(operaterID, 'sessionidtest')
  }, [])

  return (
    <div className={'edit-wrapper'}>
      { isLoading ? <Loading /> : null }
      <h2><div className={'icon-wrapper'}><I_search/></div> × {Math.floor(f_params.maguni * 10) / 10}</h2>
      <ExploreFrame
        onClick  = {handleClick}
        f_params = {f_params}
      />
      <div className={'icons-wrapper'}>
        <div className={'icon-wrapper reset'} id={'reset'} onClick={handleShrink}><I_reset /></div>
        <div className={'icon-wrapper shrink'} id={'shrink'} onClick={handleShrink}><I_shrink /></div>
        <div className={'icon-wrapper rgb'} id={'rgb'} onClick={bottonToColor}><I_rgb/></div>
      </div>
    </div>
  )
}

export default Edit;
