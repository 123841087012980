import React from 'react';

const I_rgb = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 512 512"
      // style="enable-background:new 0 0 512 512'}}
    >
      <circle style={{fill: '#006DF0'}} cx="355.225" cy="341.5" r="156.775"/>
      <circle style={{fill: '#D80027'}} cx="255.53" cy="170.5" r="156.775"/>
      <circle style={{fill: '#91DC5A'}} cx="156.775" cy="341.5" r="156.775"/>
      <path style={{fill: '#FFFFFF'}} d="M399.023,199.001c0,0-100.395-13.715-143.023,21.116c-42.88-35.039-142.023-21.116-142.023-21.116s34.389,98.73,86.343,118.271c-8.463,54.14,55.184,124.018,55.681,124.018c0.499,0,64.229-70.123,55.621-124.374C363.214,297.131,399.023,199.001,399.023,199.001z"/>
      <path style={{fill: '#EA348B'}} d="M256,220.117c14.291,11.678,26.754,26.087,36.53,43.019c9.888,17.128,16.168,35.347,19.092,53.78c51.591-19.786,90.066-66.045,98.784-122.155C357.575,174.829,298.627,185.286,256,220.117z"/>
      <path style={{fill: '#99EFF2'}} d="M311.624,316.916c-17.426,6.683-36.343,10.356-56.121,10.356c-19.423,0-38.015-3.54-55.182-9.999c-8.463,54.14,12.08,110.071,55.681,145.633C299.699,427.266,320.232,371.167,311.624,316.916z"/>
      <path style={{fill: '#FFDA44'}} d="M200.32,317.272c2.9-18.554,9.198-36.898,19.152-54.136c9.774-16.932,22.238-31.339,36.529-43.019c-42.88-35.039-102.273-45.415-155.344-25.003C109.535,251.405,148.367,297.731,200.32,317.272z"/>
      <path style={{fill: '#FFFFFF'}} d="M256,220.117c-14.291,11.678-26.753,26.087-36.529,43.019c-9.952,17.24-16.251,35.584-19.152,54.136c17.167,6.458,35.759,9.999,55.182,9.999c19.778,0,38.695-3.671,56.121-10.356c-2.926-18.434-9.204-36.653-19.092-53.78C282.756,246.204,270.292,231.797,256,220.117z"/>
    </svg>
  )
}
export default I_rgb
