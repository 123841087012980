import React     from 'react'
import Popup     from 'util/00_atoms/popup/popup'
import Separator from 'util/00_atoms/separator/separator'
import './descriptionPopup.scss'

const DescriptionPopup = (props) => {
  return (
    <Popup
      isShown      = {props.isShown}
      popupHandler = {props.popupHandler}
    >
      <Description
        description={props.description}
        title      ={props.title}
      />
    </Popup>
  )
}

const Description = (props) => {
  return (
    <div className={'description-wrapper'}>
      <h2>{props.title}</h2>
      <Separator/>
      <br/>
      {convertIndent(props.description)}
    </div>
  )
}

export default DescriptionPopup


// convert indent
function convertIndent(string) {
  let s = null
  if (typeof(string) === 'string') {
    s = string.split('\n').map((t, index) => t ? (<p key={t + index}>{t}</p>) : <br/>)
  }
  return s
}
