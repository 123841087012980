import React, {
  useState,
  useContext,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll  from "react-infinite-scroller"
import ReactDOM from 'react-dom/client';

import Axios from 'axios';

import { UserContext } from 'app'
import * as u          from 'util/util'
import ScrollToTop from 'util/00_atoms/scrollToTop/scrollToTop'
import Title       from 'util/01_molecules/title/title'
import './home.scss'

const Home = () => {

  const navigate = useNavigate()

  const onClick = (e) => {
    let id = e.currentTarget.id
    let url = '/emotion/' + id
    navigate(url)
  }


  return (
    <div>
      <ScrollToTop/>
      <Title />
      <ImageList
        onClick ={onClick}
      />
    </div>
  )
}

const ImageList = (props) => {
  const operaterID = useContext(UserContext)

  //表示するデータ
  const [list, setList] = useState([])
  const [imgList, setImgList] = useState([])
  const [imgs, setImgs] = useState([])
  const [hasMore, setHasMore] = useState(true)
  // const [loadItem, setLoadItem] = useState([1,2,3,4,5])
  const loadItem = [1,2,3,4,5]

  //項目を読み込むときのコールバック
  const loadMore = async (page) => {
    if (page >= 500) {
      setHasMore(false)
    }
    let imgListTmp, data
    let url

    url =  u.SERVER_URL + '/imgList'
    url += '?user=' + operaterID
    url += '&index=' + page.toString()

    try {
      const res = await Axios.get(url)

      imgListTmp = [...imgList]
      data = [...res.data]

      const imgsTmp = await loadImages(data)

      setList([...list, page])
      setImgList([...imgListTmp, ...data])
      setImgs([...imgs, ...imgsTmp])

    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  //各スクロール要素
  const items = (
      // imgList.map((contents, index) => {
      imgs.map((contents, index) => {
        // let flag = Math.floor(Math.random() * 8)
        let flag = index % 8

        let additionalClassName = ''

        if (flag === 1) {
          additionalClassName =  ' box-big1'
        } else if (flag === 2) {
          additionalClassName =  ' box-big2'
        } else if (flag === 3) {
          additionalClassName =  ' box-big3'
        }

        // let src = imgList[Math.floor(Math.random() * imgList.length)]
        // let src = imgList[value]

        return (
          <div key={index} className={'item' +  additionalClassName}>
            <a onClick={props.onClick} id={imgList[index].img_id}>
              {
                // <img src={u.FILE_SERVER_URL + '/image?id=' + contents.img_id + '&thum=true'}/>
                <img src={contents.src}/>
              }
            </a>
          </div>
        )
      }));

  //ロード中に表示する項目
  // const loader =<div className="loader" key={0}>Loading ...</div>;
  const loader = (
    loadItem.map((contents, index) => {
      // let flag = index % 8
      let flag = contents % 8

      let additionalClassName = ''

      if (flag === 1) {
        additionalClassName =  ' box-big1'
      } else if (flag === 2) {
        additionalClassName =  ' box-big2'
      } else if (flag === 3) {
        additionalClassName =  ' box-big3'
      }

      return (
        <div key={index} className={'item loadingtest' +  additionalClassName}>
          <a></a>
        </div>
      )
    })
  )

  return (
    <div className="cocontainer">
      <InfiniteScroll
        loadMore={loadMore}    //項目を読み込む際に処理するコールバック関数
        hasMore={hasMore}         //読み込みを行うかどうかの判定
        loader={loader}
        element='center'
      >
        {items}             {/* 無限スクロールで表示する項目 */}
      </InfiniteScroll>
    </div>
  )
}

export default Home;

// load image function
async function loadImages(imgList){
  const promises = [];
  const images = [];
  imgList.forEach(function(contents){
    var promise = new Promise(function(resolve){
      const img = new Image();
      img.onload = function(){
        // console.log('loaded : ' + u.FILE_SERVER_URL + '/image?id=' + contents.img_id + '&thum=true');
        resolve();
      }
      img.src = u.FILE_SERVER_URL + '/image?id=' + contents.img_id + '&thum=true';

      images.push(img)
    });
    promises.push(promise);
  });
  /// すべて読込完了まで待つ
  await Promise.all(promises);
  return images;
}
