import React, {
  useState,
  useEffect,
  createContext,
} from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Axios from 'axios';

import Color    from 'color/color';
// import Color    from 'colorUseLocal/color';
import Test     from 'test/test'
import Edit     from 'edit/edit'
import About    from 'about/about'
import Home     from 'home/home'
import Emotion  from 'emotion/emotion'
import Emotions from 'emotion/emotions/emotions'
import User     from 'user/user'
import PostAuthenticate from 'authenticate/authenticate';
import Register from 'register/register';
// import Login    from 'util/login/login'
import Header   from 'util/header/header'
import Login    from 'util/login/login'
import Loading  from 'util/00_atoms/loading/loading'
import UserCreate from 'user/userCreate'
import Children from 'children/children'
import * as u from 'util/util'
// import ScrollToTop from 'util/00_atoms/scrollToTop/scrollToTop'

export const UserContext = createContext()

const App = () => {
  const [operateID, setOperateID]   = useState('')
  const {
    isLoading,
    getIdTokenClaims,
  } = useAuth0();

  const firstRenderFunc = async () => {
    try {
      const idToken = await getIdTokenClaims()
      let id
      if (idToken !== undefined) {
        id = idToken.sub.split('|')[1]
      } else {
        id = null
      }

      const res2 = await Axios.get(u.SERVER_URL + '/auth?id=' + id)

      setOperateID(res2.data.id)
    }
    catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(!isLoading) firstRenderFunc()
  }, [isLoading])

  return (
    <div className="App">
      {isLoading ? <Loading/> :
        <UserContext.Provider value={operateID}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/color" element={<Color />} />
            <Route path="/edit" element={<Edit />} />
            <Route path="/about" element={<About />} />
            <Route path="/authenticate" element={<PostAuthenticate />} />
            <Route path="/register" element={<Register />} />
            <Route path="/children/:id" element={<Children />} />
            <Route path="/test" element={<Test />} >
            </Route>
            <Route path="/UserCreate" element={<UserCreate />} />
            <Route path="/emotion" element={<Emotion />} >
              <Route path=':id' element={<Emotions/>}　/>
            </Route>
            <Route path=":id" element={<User />} />
          </Routes>
        </UserContext.Provider>

      }
    </div>
  );
}

export default App;
