import React, {
   useState,
 } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Button from 'util/00_atoms/button/button'
import Popup  from 'util/00_atoms/popup/popup'

import SignIn from './signIn/signIn'
import SignUp from './signUp/signUp'

const LoginButton = () => {
  const [ isPopup, setIsPopup ]      = useState(false)
  const [ st, setSt ]                = useState('signIn')
  const { loginWithRedirect, login, loginWithPopup } = useAuth0();
  const navigate = useNavigate()

  const handleClick = () => {
    setIsPopup(true)
  }

  const popupHandler = (e) => {
    e.stopPropagation()
    let id = e.currentTarget.id

    if(id === 'popup-back') {
      setIsPopup(false)
    }
  }

  const changeRole = () => {
    // alert('pushed changeRole button!!!')
    // setSt('signUp')
    if (st === 'signIn') {
      setSt('signUp')
    } else if (st === 'signUp') {
      setSt('signIn')
    }
  }

  const renderContents = () => {
    if (st === 'signIn') {
      return (
        <>
        <SignIn/>
        <p>Not registered?</p>
        <a class='link' onClick={changeRole}>Create an account</a>
        </>
      )
    } else if (st === 'signUp') {
      return(
        <>
        <SignUp/>
        <p>Already have account?</p>
        <a class='link' onClick={changeRole}>Login</a>
        </>
      )
    }
  }

  return (
    <div>
      {
        <Button onClick={loginWithRedirect}>login</Button>
      }
      {
        // <>
        //   <Button onClick={handleClick}>login</Button>
        //   <Popup
        //     isShown      = {isPopup}
        //     popupHandler = {popupHandler}
        //   >
        //
        //   renderContents()
        //   </Popup>
        // </>
      }

    </div>
  )
}

export default LoginButton;
