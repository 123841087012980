import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  createRef,
} from 'react'
import {
  // useLocation,
  // useNavigate,
  // useParams,
  // useSearchParams
} from 'react-router-dom';
// import { useAuth0 } from "@auth0/auth0-react";
// import Axios from 'axios';
import * as u from 'util/util'
import {
  // useOnScreen,
  useIntersectionObserver,
 } from 'util/hooks'
import './about.scss'
import { ABOUT_CONTENTS } from './constants'

// import img1 from './img/sample1.png'
// import img2 from './img/sample2.jpg'
// import img3 from './img/sample3.jpg'


const About = () => {
  // const [hList, setHList] = useState([...Array(20)].map((_, i) => i + 1))
  // const testRef = useRef(null)
  const hListRefs = useRef([]);
  const tListRefs = useRef([]);
  const iListRefs = useRef([]);
  // const tVPosi  = useOnScreen(testRef)

  // for IntersectionObserver callback
  const showElements = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('VISIBLE') // 画面内に表示中
        return;
      }
      if (entry.boundingClientRect.top > 0) {
        entry.target.classList.remove('VISIBLE') // 画面より下に表示中
      } else {
        // entry.target.classList.remove('VISIBLE') // 画面より上に表示中
      }
    })
  }


  const renderHList = ABOUT_CONTENTS.map(
    (contents, index) => {

      let key = 'sec-wrap' + index.toString()

      hListRefs.current[index] = createRef();
      tListRefs.current[index] = createRef();
      iListRefs.current[index] = createRef();

      return (
        <div
          className={'sec-wrap'}
          key={key}
        >
          <h1
            className={'h-contents'}
            key={key}
            ref={hListRefs.current[index] }
          >
            {contents.header}
          </h1>
          <div
            className={'describe'}
            ref={tListRefs.current[index] }
          >
            {u.convertIndent(contents.text, key)}
            <img ref={iListRefs.current[index] } src={contents.image}/>
          </div>
        </div>
      )
    }
  )

  useIntersectionObserver(hListRefs.current, showElements)
  useIntersectionObserver(tListRefs.current, showElements)
  useIntersectionObserver(iListRefs.current, showElements)

  return (
    <div className={'about-wrapper'}>
      <div className={'contents'}>
        {renderHList}
        {
          // <h1 ref={testRef} className={'test ' + tVPosi}>observe-test!!!!!!!!</h1>
          // renderHList
        }
     </div>
    </div>
  )
}

export default About;
