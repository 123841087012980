import Axios from 'axios';
import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import * as u from 'util/util'

// custom hooks test
export const useIsScroll = () => {
  const [isScrolling, setIsScrolling] = useState(false)

  let timeoutId

  const handleScroll = useCallback(() => {
    // isScrolling = 1 ;
    setIsScrolling(true)

    clearTimeout( timeoutId ) ;

    timeoutId = setTimeout(() => {
        // isScrolling = 0 ;
        setIsScrolling(false)
    }, 5000 )
  },[])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return isScrolling
}

// type TargetViewPosition = undefined | 'ABOVE_VIEWPORT' | 'BELOW_VIEWPORT' | 'VISIBLE';

export const useOnScreen = (targetRef: React.RefObject<HTMLElement>) => {
  const [targetViewPosition, setTargetViewPosition] = useState(undefined);

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        setTargetViewPosition('VISIBLE'); // 画面内に表示中
        return;
      }
      if (entry.boundingClientRect.top > 0) {
        setTargetViewPosition('BELOW_VIEWPORT'); // 画面より下に表示中
      } else {
        setTargetViewPosition('ABOVE_VIEWPORT'); // 画面より上に表示中
      }
    },
    {
      root: null,
      threshold: 0,
    }
  );

  useEffect(() => {
    // マウント時にobserverを登録
    if (targetRef.current) observer.observe(targetRef.current);

    // アンマウント時にobserverを解除
    return () => {
      observer.disconnect();
    };
  }, []);

  return targetViewPosition;
}

export const useIntersectionObserver = (
  refs: RefObject<HTMLElement>[],
  callback: (entries: IntersectionObserverEntry[]) => void,
  options?: IntersectionObserverInit
): void => {
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
    }
  })
}
