// let MAX_VALUE = 255
// let MIN_VALUE = 0

export const MAX_VALUE = 255
export const MIN_VALUE = 0

// for local -------------------------------------
// export const SERVER_URL                = 'http://127.0.0.1:5000'
// export const FILE_SERVER_URL           = 'http://127.0.0.1:5000'
// export const APP_ORIGIN                = 'https://127.0.0.1:3000'
// export const AUTH0_DOMAIN              = 'dev-a95ftamt.us.auth0.com'
// export const AUTH0_CLIENT_ID           = 'rutHkynUGFjKciJslq7yCfpYritT42m9'
// export const AUTH0_LOGIN_REDIRECT_URI  = APP_ORIGIN + '/authenticate'
// export const AUTH0_LOGIN_RESPONSE_TYPE = 'token'
// export const AUTH0_USER_SCOPE          = 'openid email profile'
// export const AUTH0_REALM               = 'Username-Password-Authentication'

// for 'conoha server' ---------------------------
export const SERVER_URL = 'https://www.closm.top'
export const FILE_SERVER_URL = 'https://www.closm.top'
export const APP_ORIGIN = 'https://www.closm2.top'
export const AUTH0_DOMAIN = 'dev-a95ftamt.us.auth0.com'
export const AUTH0_CLIENT_ID = 'W7Y9W915x8IkDkL57cbGxlh4lotdtZ82'
export const AUTH0_LOGIN_REDIRECT_URI = APP_ORIGIN + '/authenticate'
export const AUTH0_USER_SCOPE = 'openid email profile'
export const AUTH0_REALM = 'Username-Password-Authentication'
export const AUTH0_LOGIN_RESPONSE_TYPE = 'token'

export const DEFAULT_F_PARAMS = {
  h           : 0,
  w           : -1,
  maguni      : 0.3,
  resolution  : 1000,
  repetition  : 1000,
}

export const DEFAULT_COLOR_BAR_DATA = [
  {
    rgb: {red : MIN_VALUE, green: MIN_VALUE, blue : MIN_VALUE,},
    colorPoint : MIN_VALUE,
  },
  {
    rgb: {red : 80, green: 80, blue : 80,},
    colorPoint : 80,
  },
  {
    rgb: {red : 180, green: 180, blue : 180,},
    colorPoint : 180,
  },
  {
    rgb: {red : MAX_VALUE, green: MAX_VALUE, blue : MAX_VALUE,},
    colorPoint : MAX_VALUE,
  },
]

export const DEFAULT_JS_F_PARAMS = {
  h           : 0,
  w           : -1,
  maguni      : 0.3,
  resolution  : 100,
  repetition  : 100,
}

export const updateList = function(list, listIndex, contents){
  return (
      list.map((list, index) => (index === listIndex ? contents : list))
  );
};

export const convertToRGB = function(colorCode) {

  let rgb = {}

  rgb.red   = parseInt(colorCode.substring(1,3), 16);
  rgb.green = parseInt(colorCode.substring(3,5), 16);
  rgb.blue  = parseInt(colorCode.substring(5,7), 16);

  return rgb
}

export const convertToColorcode = function(rgb){
  const r = Number(rgb.red);
  const g = Number(rgb.green);
  const b = Number(rgb.blue);
  let color_code;

  color_code = (
    '#'
    + ('0' + r.toString(16)).slice( -2 )
    + ('0' + g.toString(16)).slice( -2 )
    + ('0' + b.toString(16)).slice( -2 )
  )
  return color_code;
}

function separateColor(color, position, colorBarPosition) {
  let color1 = 0
  let color2 = 0

  let start = null
  let end   = null

  let o_color = 0

  if (color.length <= 2) {
    color1 = color[0]
    color2 = color[1]
    start = MIN_VALUE
    end   = MAX_VALUE
  } else {
    for (var i = 0; i < color.length - 1; i += 1) {
      if(position[i] <= colorBarPosition && colorBarPosition < position[i + 1]) {
        color1 = color[i]
        color2 = color[i + 1]
        start  = position[i]
        end    = position[i + 1]
      }
    }
  }

  o_color = 1 / (end - start) * ((end - colorBarPosition) * color1 + (colorBarPosition - start) * color2)

  return o_color
}

export const createImage = function(imageData, col_ar, colorBarData){
  let j = 0
  for (let i = 0; i < imageData.length; i += 4) {
    let w = (i / 4) % col_ar[1].length

    if (j < col_ar.length) {
      var col_vol = col_ar[j][w]
    }
    imageData[i]     = separateColor(colorBarData.redArray  , colorBarData.colorPoint, col_vol);  // red
    imageData[i + 1] = separateColor(colorBarData.greenArray, colorBarData.colorPoint, col_vol);  // green
    imageData[i + 2] = separateColor(colorBarData.blueArray , colorBarData.colorPoint, col_vol);  // blue
    imageData[i + 3] = 255;      // alfa
    if((w === 0) && (i !== 0)){
      j += 1
    }
  }
}

export const getRgbFromColorbar = function(colorBarData, index) {
  let rgb = {
    red  : colorBarData.redArray[index],
    green: colorBarData.greenArray[index],
    blue : colorBarData.blueArray[index],
  }
  return rgb
}

export const objectSetter = function(object, elementName, contents) {
  return { ...object, [elementName]: contents }
}

export function convertIndent(string :String, foreignKey :String) {
  let s = null
  if (typeof(string) === 'string') {
    s = string.split('\n').map((t, index : Number) => (
      t ? (
        <p key={t + index.toString() + foreignKey}>{t}</p>
      )
      : <br key={index.toString() + foreignKey}/>
    ))
  }
  return s
}

export const createColorBarArray = (colorBarData) => {
  let colorBarArray = {
    red  : [],
    green: [],
    blue : [],
  }

  let color1 = colorBarData[0].rgb
  let color2 = colorBarData[1].rgb

  let index = colorBarData[0].colorPoint
  let posiIndex = 1

  let start = colorBarData[0].colorPoint
  let end   = colorBarData[1].colorPoint


  for(index = 0; index <= colorBarData.slice(-1)[0].colorPoint; index += 1){

    // When you reach the color point
    if(index == colorBarData[posiIndex].colorPoint && index != 255){

      color1 = colorBarData[posiIndex].rgb
      color2 = colorBarData[posiIndex + 1].rgb

      start = colorBarData[posiIndex].colorPoint
      end   = colorBarData[posiIndex + 1].colorPoint

      posiIndex += 1
    }

    colorBarArray.red[index] = Math.floor(
       1 / (end - start) * ((end - index) * color1.red + (index - start) * color2.red)
    )
    colorBarArray.green[index] = Math.floor(
       1 / (end - start) * ((end - index) * color1.green + (index - start) * color2.green)
    )
    colorBarArray.blue[index] = Math.floor(
       1 / (end - start) * ((end - index) * color1.blue + (index - start) * color2.blue)
    )
  }
  return colorBarArray
}

export const createImage2 = (imageData, colorBarArray, imageDataTmp) => {
  for (let i = 0; i < imageData.length; i += 4) {
    imageData[i]     = colorBarArray.red[imageDataTmp[i]]  // red
    imageData[i + 1] = colorBarArray.green[imageDataTmp[i + 1]]  // green
    imageData[i + 2] = colorBarArray.blue[imageDataTmp[i + 2]]  // blue
    imageData[i + 3] = 255;      // alfa
  }

}

export const loadImage = async (imgUrl) => {
  var img = null;
  var promise = new Promise(function(resolve){
    img = new Image();
    img.onload = function(){
      /// 読み込み完了後...
      console.log('loaded : '+imgUrl);
      resolve();
    }
    /// 読み込み開始...
    img.src = imgUrl;
  });
  /// 読込完了まで待つ
  await promise;
  return img;
}
