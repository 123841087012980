import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Axios from 'axios';
import * as u from 'util/util'
import Button  from 'util/00_atoms/button/button'
import Loading from 'util/00_atoms/loading/loading'
import './user.scss'


const UserCreate = () => {
  const { loginWithRedirect, login } = useAuth0();

  const [auth, setAuth] = useState({
    username   : 'isamu',
    hashedPass : 'Isamu1997',
    email      : 'isamu41117991@gmail.com',
    status     : 0,    // 0: non data, 1: username done, 2: pass done, 3: email done
  })

  const [error, setError] = useState({
    isError : false,
    message : ''
  })

  const onChange = (e) => {
    let ui_tmp = {...auth}
    ui_tmp[e.target.id] = e.target.value
    setAuth(ui_tmp)
  }

  const onSubmit = (e) => {
    let ui_tmp = {...auth}
    ui_tmp.status = auth.status + 1
    console.log(e.target.innerText)
    console.log(ui_tmp.status)
    setAuth(ui_tmp)

    if (ui_tmp.status === 3){
      // test
      ui_tmp.status = 0
      setAuth(ui_tmp)
      // test
      registUserInfo()
    }
  }

  const registUserInfo = async () => {
    // alert(JSON.stringify(auth))
    let url = u.SERVER_URL + '/auth'

    let send = {
      "client_id": "rutHkynUGFjKciJslq7yCfpYritT42m9",
      "email": auth.email,
      "password": auth.hashedPass,
      "connection": "Username-Password-Authentication",
      "username": auth.username,
      // "given_name": "",
      // "family_name": "",
      // "name": '',
      // "nickname": "",
      // "picture": null,
      // "user_metadata": {contact : auth.contact}
    }

    try {
      const res2 = await Axios.post('https://dev-a95ftamt.us.auth0.com/dbconnections/signup', send)
      console.log(res2)

      // const res1 = await Axios.post(url, auth)
      // console.log(res1)
      //
      // url = 'https://dev-a95ftamt.us.auth0.com/authorize?'
      // url += 'response_type=code|token&'
      // url += 'rclient_id=rutHkynUGFjKciJslq7yCfpYritT42m9&'
      // url += 'connection=Username-Password-Authentication&'
      // url += 'redirect_uri=https://127.0.0.1:3000&'
      // // url += 'state=STATE&
      // // url += 'ADDITIONAL_PARAMETERS
      const res3 = await Axios.post(url, {
        auth     : auth,
        id_auth0 : res2.data._id
      })
      console.log(res3)

      setError(false)

      loginWithRedirect()

    } catch (e) {
      console.error(e.response.data)
      let er_tmp = {...error}
      er_tmp.isError = true
      er_tmp.message = e.response.data.message
      setError(er_tmp)
    }
  }

  const renderForm = () => {
    // let r = <InUserName/>
    let r = null
    if (auth.status === 0) {
      r = <InUserName onChange={onChange} auth={auth}/>
    } else if (auth.status === 1) {
      r = <InPassWord onChange={onChange} auth={auth}/>
    } else if (auth.status === 2) {
      r = <InContact onChange={onChange} auth={auth}/>
    }
    return r
  }

  const renderButton = () => {
    // let r = <InUserName/>
    let r = null
    if (auth.status === 2) {
      r = <Button onClick={onSubmit}>regist</Button>
    } else {
      r = <Button onClick={onSubmit}>next ⇨</Button>
    }
    return r
  }


  return (
    <div className='userCreate-wrapper'>
      {error.isError ? error.message : null}
      {renderForm()}
      {renderButton()}
    </div>
  )
}

const InUserName = (props) => {
  return (
    <div className='inUserName-wrapper'>
      <label htmlFor="username">username</label>
      <input
        value={props.auth.username}
        type="text"
        id="username"
        onChange={props.onChange}
      ></input>
    </div>
  )
}

const InPassWord = (props) => {
  return (
    <div className='inPassWord-wrapper'>
      <label htmlFor="hashedPass">hashedPass</label>
      <input
        value={props.auth.hashedPass}
        type="text"
        id="hashedPass"
        onChange={props.onChange}
      ></input>
    </div>
  )
}

const InContact = (props) => {
  return (
    <div className='inContact-wrapper'>
      <label htmlFor="email">email</label>
      <input
        value={props.auth.email}
        type="email"
        id="email"
        onChange={props.onChange}
      ></input>
    </div>
  )
}

export default UserCreate;
