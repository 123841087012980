import React, {
  useEffect,
  useState,
  // useRef,
  // useCallback
} from 'react'
import {
  // useLocation,
  useNavigate,
  useParams,
  // useSearchParams
} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Axios from 'axios';
import * as u from 'util/util'
import './children.scss'


const Children = () => {
  const [children, setChildren] = useState([])
  const [parent, setParent] = useState()
  const [axiosLoading, setAxiosLoading] = useState(true)
  const navigate = useNavigate()

  const {
    isLoading
  } = useAuth0();

  const params = useParams();

  const firstRenderAction = async () => {
    if (!isLoading) {
      setAxiosLoading(true)
      try {
        const res1 = await Axios.get(u.SERVER_URL + '/children?id=' + params.id)

        setChildren(res1.data.children)
        setParent(res1.data.parent)
      }
      catch(error) {
        alert(error.response.data.message)
        alert('MOVE TO TOP PAGE')
        navigate('/')
      }
      setAxiosLoading(false)
    }
  }

  useEffect(() => {
    if (params.id !== null) {
      firstRenderAction()
    }else {
      alert('not found CHILDREN!!!!!')
    }
  }, [isLoading])



  const renderImgs = () => {
    // let images
    let url = ''
    url += u.SERVER_URL + '/image?id='

    if (children.length === 0) {
      return <h2>THE EMOTION IS ONLY ONE</h2>
    }

    return (
      children.map((contents, index) => {
        return (
          <div className={'children-img-items'} key={contents.img_id + index}>
            <img
              onClick={() => navigate('/emotion/' + contents.img_id)}
              src={url + contents.img_id}
            ></img>
          </div>
        )
      })
    )
  }

  return (

    <div className={'children-wrapper'}>
    {axiosLoading ? null :
      <>
      <div className={'children-header'}>
        <div className={'children-icon'}>
          <img
          src={u.SERVER_URL + '/image?id=' + parent.img_id}
          onClick={() => navigate('/emotion/' + parent.img_id)}
          />
        </div>
        <div className={'children-description'}>{parent.title}</div>

      </div>
      <div className={'children-contents'}>
        {renderImgs()}
      </div>
      </>
    }
    </div>
  )
}

export default Children;
