import React, {useState, useEffect} from 'react'
import * as u from 'util/util'
import Popup     from 'util/00_atoms/popup/popup'
import './imagePopup.scss'

const ImagePopup = (props) => {
  const [dataURL, setDataURL] = useState('')

  useEffect(() => {
    if (props.isShown === true) {
      const canvas = document.getElementById('canvas')
      if(canvas !== null) {
        let d = canvas.toDataURL()
        setDataURL(d)
      } else if(props.img_id !== null){
        // let img = document.getElementById('dispImg')
        // let src = img.getAttribute('src');

        let src = u.SERVER_URL + '/image?id=' + props.img_id
        setDataURL(src)
      }
    }
  }, [props.isShown])

  return (
    <Popup
      isShown      = {props.isShown}
      popupHandler = {props.popupHandler}
    >
      <div className={'imagePopup-wrapper'}>
        <img src={dataURL}></img>
      </div>
    </Popup>
  )
}

export default React.memo(ImagePopup)
