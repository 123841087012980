import React, { useRef, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import ReactDOM from 'react-dom/client';
import Axios from 'axios';
import ColorImg         from './colorImg/colorImg'
import HistoryPopup     from './historyPopup/historyPopup'
import {useAuth0} from "@auth0/auth0-react";
import { UserContext } from 'app'
import Loading    from 'util/00_atoms/loading/loading'
import Button     from 'util/00_atoms/button/button'
import ColorBar   from 'util/00_atoms/colorBar/colorBar'
import RGBBox     from 'util/01_molecules/rgbBox/rgbBox'
import RGBOparate from 'util/01_molecules/rgbOparate/rgbOparate'
import WritePopup from 'util/01_molecules/writePopup/writePopup'
import ImagePopup from 'util/01_molecules/imagePopup/imagePopup'

import * as u from 'util/util'
import './color.scss'

import I_up        from 'util/icons/i_up'
import I_down      from 'util/icons/i_down'
import I_rightLeft from 'util/icons/i_rightLeft'
import I_archive   from 'util/icons/i_archive'
import I_search    from 'util/icons/i_search'

// const USER_ID    = 'usernametest'
const SESSION_ID = 'sessionidtest'

const Color = () => {
  // const [operaterID, setOperaterID]     = useState('')
  const operaterID = useContext(UserContext)
  // const [f_params, setF_param]          = useState(u.DEFAULT_F_PARAMS)
  const [imageInfo, setImageInfo]       = useState(null)
  const [gImgBase64, setGImgBase64]     = useState('')
  const [isLoadAxios, setIsLoadAxios]   = useState(true)
  const [activeIndex , setActiveIndex]  = useState(1)
  const [colorBarData, setColorBarData] = useState(u.DEFAULT_COLOR_BAR_DATA)
  const [cbdHistory, setCbdHistory]     = useState([])
  // const [userTmp  , setUserTmp]         = useState({})
  const [isPopup, setIsPopup]           = useState(false)
  const [isHistoryPop, setIsHistoryPop] = useState(false)
  const [isImagePop, setIsImagePop]     = useState(false)

  const {
    isAuthenticated,
    // getIdTokenClaims,
    isLoading
  } = useAuth0();

  const homeRef = useRef(null);

  // Receive value from previous page
  const location = useLocation();
  const prevState = location.state;

  //  to 'info' page
  const navigate = useNavigate()

  const firstRenderFunc = async (userTmpUrl) => {
    setIsLoadAxios(true)

    let prs = {...prevState}
    let ii_tmp

    if( Object.keys(prs).length ) {
      // exist prevState
      if (prs.from === 'emotions') {
        const res1 = await Axios.get(u.SERVER_URL + '/imageInfo' + '?nw=true')
        let ii_new = {...res1.data[0]}
        ii_new.f_params      = prs.f_params
        ii_new.parent_id     = prs.parent_id
        ii_new.colorBarData  = prs.colorBarData
        ii_new.operateType   = prs.operateType
        ii_new.status        = 'from_emotion'

        ii_tmp = {...ii_new}

        let grayUrl = u.FILE_SERVER_URL + '/useLocalStorage' + '?gid=' + ii_new.f_params.gray_id

        // get image by base64
        const imgLoad = await Axios.get(grayUrl)
        setGImgBase64(imgLoad.data)

      } else if (prs.from === 'edit') {
        ii_tmp = {...prs.imageInfo}
        ii_tmp.f_params.resolution = 1000

        const res4 = await Axios.post(u.SERVER_URL + '/useLocalStorage', ii_tmp)

        setGImgBase64(res4.data)
      }

    } else {
      // prevState none.
      window.alert('invalid access. Move to home.')
      navigate('/')
    }
    setColorBarData(ii_tmp.colorBarData)
    setImageInfo(ii_tmp)
    setIsLoadAxios(false)
  }

  const postInfo = async (e, user_id, session_id) => {
    setIsLoadAxios(true)

    let canvas = document.getElementById('canvas');
    let data = canvas.toDataURL('image/png')
    let ii_tmp = {...imageInfo}

    ii_tmp.colorBarData = [...colorBarData]
    ii_tmp.title = e.target.title.value
    ii_tmp.description = e.target.description.value

    if (isAuthenticated) {
      ii_tmp.owner = operaterID
    }

    if (ii_tmp.f_params.gray_id === '' || ii_tmp.f_params.gray_id === 0) {
      const gRes = await Axios.post(u.FILE_SERVER_URL + '/image', {
        imageData : gImgBase64,
        imageName : '',
        user      : '',
        gray      : true,
      })
      ii_tmp.f_params.gray_id = gRes.data.gray_id
      console.log(gRes)
    }

    const res2 = await Axios.post(u.SERVER_URL + '/info', {
      // userTmp   : ut1,
      imageInfo : ii_tmp,
      imageData : data,
    })

    const res3 = await Axios.post(u.FILE_SERVER_URL + '/image', {
      imageData : data,
      imageName : res2.data.img_id,
      user      : '',
      gray      : false,
    })

    console.log(res3.data.img_id)

    // page transition
    // navigate('/info?id=' + res.data.img_id)
    navigate('/emotion/' + res2.data.img_id)
  }

  const handleInfoSubmit = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to confirm the current contents?')) {
      postInfo(e, operaterID, 'sessionidtest')
    }
  }

  const changeIndex = (e) => {
    let idxTmp = activeIndex
    if (e.currentTarget.id === 'down') {
      if(idxTmp !== colorBarData.length - 1) {
        idxTmp += 1
      }
    }
    if (e.currentTarget.id === 'up') {
      if(idxTmp !== 0) {
        idxTmp -= 1
      }
    }
    setActiveIndex(idxTmp)
  }

  const updateColorBarData = (e) => {
    let cbdTmp = colorBarData.slice()
    let index = parseInt(e.target.id, 10)
    let value = parseInt(e.target.value, 10)

    cbdTmp[index].colorPoint = value

    setColorBarData(cbdTmp)
  }

  const handleChangeColor = (e) => {
    let colorBarDataTmp = [...colorBarData]

    colorBarDataTmp[activeIndex]
      .rgb[e.target.name] = parseInt(e.target.value)

    setColorBarData(colorBarDataTmp)
  }

  const popupHandler = (e) => {
    e.stopPropagation()
    let id = e.currentTarget.id

    if(id === 'popup-back') {
      setIsPopup(false)
      setIsHistoryPop(false)
      setIsImagePop(false)
    } else if (id === 'archive') {
      setIsHistoryPop(true)
    } else if (id === 'colorImg-post'){
      setIsPopup(true)
    } else if (id === 'colorImg-contents') {
      setIsImagePop(true)
    }
  }

  const renderInputRange = () => {
    // let cpTmp = props.colorBarData.colorPoint
    let cbd = [...colorBarData]

    if (activeIndex === cbd.length - 1 || activeIndex === 0) {
      return null;
    } else {
      return (
        <input
          className='r'
          type="range"
          name="0"
          id={activeIndex}
          min={cbd[activeIndex - 1].colorPoint + 1}
          max={cbd[activeIndex + 1].colorPoint - 1}
          value={cbd[activeIndex].colorPoint}
          onChange={updateColorBarData}
        />
      );
    }
  }

  const pushHistory = async () => {
    let cbdHisTmp = cbdHistory.concat()
    // deep copy
    const cbdTmp    = JSON.parse(JSON.stringify(colorBarData))
    setCbdHistory([...cbdHisTmp, {
      // img_id       : res1.data.img_id,
      colorBarData : cbdTmp,
    }])
  }

  const pullHistory = (e) => {
    let index = e.currentTarget.dataset.index
    // let newCbd = cbdHistory[index].colorBarData.slice()
    let newCbd = JSON.parse(
      JSON.stringify(
        cbdHistory[index].colorBarData.slice()
      )
    )
    setColorBarData(newCbd)
  }

  const reverseCBD = () => {
    let cbdTmp = JSON.parse(
      JSON.stringify(
        colorBarData.slice()
      )
    )
    let rev    = JSON.parse(
      JSON.stringify(
        colorBarData.slice()
      )
    ).reverse()

    for (let i = 0; i < cbdTmp.length; i++) {
      rev[i].colorPoint =  cbdTmp[i].colorPoint
    }
    setColorBarData(rev)
  }

  // execute every time
  useEffect(() => {
    if(homeRef && homeRef.current) {
      homeRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  })

  useEffect(() => {
    let userTmpUrl = u.SERVER_URL + '/userTmp?'

    // userTmpUrl += 'user=' + USER_ID + '&'
    userTmpUrl += 'user=' + operaterID + '&'
    userTmpUrl += 'session=' + SESSION_ID

    if (!isLoading) firstRenderFunc(userTmpUrl)
  }, [isLoading])

  return (
    <div ref={homeRef}>
      {isLoadAxios  ? <Loading /> :
        <div className='color-wrapper'>
          <div className={'appearance-wrapper'}>
            <div className={'colorImg-wrapper'}>
              <ColorImg
                colorBarData={colorBarData}
                // f_params  = {f_params}
                gImgBase64={gImgBase64}
                imageInfo = {imageInfo}
                onClick   = {popupHandler}
              />
            </div>
              <ColorBar
                colorBarData={colorBarData}
                activeIndex={activeIndex}
              />
          </div>
          <div className='side-wrapper'>
            <div className='side-left-wrapper'>
              <RGBBox rgb={colorBarData[activeIndex].rgb}/>
              {renderInputRange()}
            </div>
            <div className={'side-right-wrapper'}>
              <div className={'icons-wrapper'}>
                <div className={'icon-wrapper'} id={'up'} onClick={changeIndex}><I_up /></div>
                <div className={'icon-wrapper'} id={'down'} onClick={changeIndex}><I_down /></div>
                <div className={'icon-wrapper reverse'} id={'rightLeft'} onClick={reverseCBD}><I_rightLeft /></div>
                <div className={'icon-wrapper archive'} id={'archive'} onClick={popupHandler}><I_archive /></div>
                <div className={'icon-wrapper edit'} id={'edit'} onClick={() => navigate('/edit', {state: {f_params: imageInfo.f_params, imageInfo: imageInfo}})}><I_search /></div>
              </div>
              <RGBOparate
                rgb={colorBarData[activeIndex].rgb}
                handleChangeColor={handleChangeColor}
              />
              <Button
                onClick={popupHandler}
                id     ='colorImg-post'
              >spill</Button>
            </div>
          </div>
          <WritePopup
           isShown          = {isPopup}
           popupHandler     = {popupHandler}
           description      = {imageInfo.description}
           title            = {imageInfo.title}
           handleInfoSubmit = {handleInfoSubmit}
          />
          <HistoryPopup
           isShown      = {isHistoryPop}
           popupHandler = {popupHandler}
           history      = {cbdHistory}
           onClick      = {pullHistory}
           pushHistory  = {pushHistory}
           gImgBase64   = {gImgBase64}
          />
          <ImagePopup
           isShown      = {isImagePop}
           popupHandler = {popupHandler}
          />
        </div>
       }
    </div>
  )
}

export default Color;
