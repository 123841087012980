import React, {useState, useEffect} from 'react'
import Popup     from 'util/00_atoms/popup/popup'
import Separator from 'util/00_atoms/separator/separator'
import './writePopup.scss'

const WritePopup = (props) => {
  const [description, setDescription] = useState({
    contents: props.description,
    rows: 0,
  })
  const [title, setTitle]             = useState(props.title)

const handleChange = (e) => {
  let id = e.currentTarget.id

  if (id === 'description') {
    let descriptionTmp = {...description}
    let text = e.target.value

    descriptionTmp.contents = text
    descriptionTmp.rows = text.split('\n').length

    setDescription(descriptionTmp)

    const textarea = document.getElementById('description');
    textarea.style.height = 80 + 'px';
    let scrollHeight = textarea.scrollHeight;
    textarea.style.height = scrollHeight + 'px';
  } else if (id === 'title') {
    setTitle(e.currentTarget.value)
  }
}

useEffect(() => {
  if (props.isShown === true) {
    setTitle(props.title)
    setDescription({
      contents: props.description,
      rows: 0,
    })
  }
}, [props.isShown])

  return (
    <Popup
      isShown      = {props.isShown}
      popupHandler = {props.popupHandler}
    >
      <div className={'writePopup-wrapper'}>
        <form onSubmit={props.handleInfoSubmit}>
          <h2><label htmlFor="title">title</label></h2>
          <Separator/>
          <input
          type="text"
          id="title"
          value={title}
          onChange={handleChange}
          ></input><br/><br/>
          <h2><label htmlFor="description">description</label></h2>
          <Separator/>
          <textarea
            id="description"
            value={description.contents}
            onChange={handleChange}
          ></textarea><br/><br/>
          <input type="submit" value='handleInfoSubmit'/>
        </form>
      </div>
    </Popup>
  )
}

export default WritePopup
