import React, { useState, useEffect, useCallback } from 'react';
import './header.scss'

import LoginState      from 'util/login/loginState'
import HamburgerToggle from 'util/00_atoms/hamburgerToggle/hamburgerToggle'
import HeaderPopup     from 'util/01_molecules/headerPopup/headerPopup'

const Header = () => {
  const [isDisp, setIsDisp]   = useState(true)
  const [isShown, setIsShown] = useState(false)

  const headerVisibility = useCallback(() => {
    window.scrollY > window.visualViewport.height - 100
      ? setIsDisp(false)
      : setIsDisp(true)
  })

  const popupHandler = (e) => {
    e.stopPropagation()
    let id = e.currentTarget.id

    if(id === 'popup-back' || id === 'header-item') {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', headerVisibility)
    return () => window.removeEventListener('scroll', headerVisibility)
  },[headerVisibility])

  return (
    <header
      className={'header-web' + (isDisp ? ' sticky' : ' sticky-hide')}
      id={'header'}
    >
      <HamburgerToggle
        onClick = {popupHandler}
        isOpen  = {isShown}
      />
      <HeaderPopup
        isShown      = {isShown}
        popupHandler = {popupHandler}
      />
      <LoginState />
    </header>
  )
}

export default Header;
