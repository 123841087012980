import React, { useState, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';
import {
  complex,
  multiply,
  abs,
  add,
} from 'mathjs'

import Loading from '../../util/00_atoms/loading/loading'
import './exploreFrame.scss'

// let isLoading = false

const ExploreFrame = (props) => {
  // const [zArray, setZArray]     = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)

  const firstRenderFunc = async () => {
    // isLoading = true

    let fp_tmp
    if (Object.keys(props).length !== 0) {
      try {
        const canvas = document.getElementById("canvas")
        canvas.height = props.f_params.resolution
        canvas.width  = props.f_params.resolution
        const ctx = canvas.getContext("2d")

        fp_tmp = {...props.f_params}

        const za_tmp = await mandelbrot(fp_tmp)

        if(ctx !== null) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const myImageData  = ctx.createImageData(canvas.width, canvas.height);
          const data  = myImageData.data;
          createImage(data, za_tmp)
          ctx.putImageData(myImageData, 0, 0);

          setImageUrl(canvas.toDataURL())
          // setZArray(za_tmp)
        }
      }
      catch (e) {
        console.error(e)
      }
    }
    // isDispEl.style.display = 'none'
    setIsLoading(false)

  }

  useEffect(()=>{
    firstRenderFunc()
  },[props.f_params,])

  return(
    <div className={'exploreFrame-wrapper'}>
      {
        // <div id='isDisp'>
        //   <Loading />
        // </div>
        isLoading ? <Loading /> : null
      }{
        // <div className={'progress-wrapper'} id={'gauge'}></div>
      }
      <img onClick={props.onClick} src={imageUrl} draggable="false" />
      <canvas style={{display : 'none'}} id='canvas'/>
    </div>
  )
}

export default ExploreFrame

// function mandelbrot(comp_value, maguni, rep, reso) {
async function mandelbrot(fp : f_params) {
  let comp_value = complex({re: fp.w, im: fp.h})
  let n_max
  // 2次元配列の生成
　let twoDAr = await create2DArray(comp_value, fp.maguni, fp.resolution)

  if   (fp.maguni < 200) {
    n_max = Math.floor(100 + 900 * (fp.maguni / 200))
  }
  else if (200 <= fp.maguni && fp.maguni < 100000) {
    n_max = Math.floor(1000 + 2000 * ((fp.maguni - 200) / (100000 - 200)))
  }

  else if (100000 <= fp.maguni && fp.maguni < 10000000) {
    n_max = Math.floor(3000 + 1000 * ((fp.maguni - 100000) / (10000000 - 100000)))
  }
  else if (fp.maguni > 10000000) {
    n_max = 4000
  }

  // atom ごとに計算
  // ここで0~1 の範囲で返す
  // let zArray = mandel_atoms(twoDAr, rep)
  // let zArray = calcMandel(twoDAr, fp.repetition)
  let zArray = await calcMandel(twoDAr, n_max)

  // 二次元配列を返す
  return zArray
}

async function create2DArray(comp_value, maguni, reso) {
  let x_max, x_min, y_max, y_min
  let i = 0
  let j = 0
  let width
  let rdc = 1 / maguni

  // init 2D Array
  let twoDAr = new Array(reso)
  for (let a = 0; a < twoDAr.length; a++) {
    twoDAr[a] = new Array(reso)
  }

  x_min = comp_value.re  -  rdc / 2
  x_max = comp_value.re  +  rdc / 2
  y_min = comp_value.im  -  rdc / 2
  y_max = comp_value.im  +  rdc / 2

  width = x_max - x_min

  while (i < reso) {
    while (j < reso) {
      twoDAr[i][j] = complex({re: x_min + rdc * (j / reso), im: y_max - rdc * (i / reso)})
      j++
    }
    j = 0
    i++
  }
  return twoDAr
}

async function calcMandel(twoDAr, rep) {
  let i = 0
  let j = 0
  let max_value = 0
  let min_value = 1
  // let ratio

  // init 2D Array
  let zArray = new Array(twoDAr.length)
  for (let a = 0; a < zArray.length; a++) {
    zArray[a] = new Array(twoDAr.length)
  }
  while (i < twoDAr.length) {
    while (j < twoDAr.length) {
      let value = await mandel_atoms(twoDAr[i][j], rep)
      zArray[i][j] = value
      j++

      if(max_value < value) {
        max_value = value
      } else if (min_value > value) {
        min_value = value
      }
    }

    // progress
    if (Math.floor(i * j / (twoDAr.length * twoDAr.length) * 100) % 10 === 0) {
      console.log('progress : ' + Math.floor(i * j / (twoDAr.length * twoDAr.length) * 100) + ' %')
      // updateGauge(Math.floor(i * j / (twoDAr.length * twoDAr.length) * 100))
    }

    j = 0
    i++
  }
  if (max_value !== 1) {
    i = 0
    j = 0
    // ratio = 1 / max_value
    while (i < twoDAr.length) {
      while (j < twoDAr.length) {
        // zArray[i][j] = zArray[i][j] * ratio
        zArray[i][j] = (zArray[i][j] - min_value) / (max_value - min_value)
        j++
      }
      j = 0
      i++
    }
  }
  // console.log(zArray)
  return zArray
}

async function mandel_atoms(comp_value, rep) {
  let i = 0
  let z0 = complex({re:0, im: 0})

  while (abs(z0) < 2 && i < rep) {
    z0 = add(multiply(z0, z0), comp_value)
    i++
  }
  return i / rep
}

// function updateGauge(gaugeLength) {
//   document.getElementById("gauge").style.width = gaugeLength + '%';
// }

function createImage(imageData, za_tmp){
  let j = 0
  for (let i = 0; i < imageData.length; i += 4) {
    let w = (i / 4) % za_tmp[1].length
    imageData[i]     = Math.floor(255 * za_tmp[j][w]) // red
    imageData[i + 1] = Math.floor(255 * za_tmp[j][w]) // green
    imageData[i + 2] = Math.floor(255 * za_tmp[j][w]) // blue
    imageData[i + 3] = 255;                           // alfa
    if((w == 0) && (i !== 0)){
      j += 1
    }
  }
}
