import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import { UserContext } from 'app'
// import { useWindowSize } from 'react-use';
import * as u from 'util/util'
import './colorImg.scss'


const ColorImg = (props) => {
  const [ctx,setCtx] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [imageDataDataAchive, setImageDataDataAchive] = useState(null)
  const [imageData, setImageData] = useState(null)
  // const [style, setStyle] = useState({
  //   height : 100,
  //   width  : 100,
  // })
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const operaterID = useContext(UserContext)

  // for window resize
  // const { width, height } = useWindowSize();
  const wrapperRef = useRef(null)

  // const changeStyle = (e) => {
  //   let s_tmp = {...style}
  //   s_tmp.height = e.target.value
  //   s_tmp.width  = e.target.value
  //   setStyle(s_tmp)
  // }

  useEffect(() => {
    const canvas = document.getElementById("canvas")

    canvas.height   = props.imageInfo.f_params.resolution
    canvas.width    = props.imageInfo.f_params.resolution

    const canvasContext = canvas.getContext("2d")

    setCtx(canvasContext)
  },[])

  // 状態にコンテキストが登録されたらそれに対して操作できる
  useEffect(() => {
    let time = new Date().getTime()

    if(ctx !== null && props.imageInfo !== null){
      const img = new Image();
      img.crossOrigin = 'Anonymous'

      // img.src = sessionStorage.getItem('grayImg')
      img.src = props.gImgBase64

      // 画像読み込み終了してから描画
      img.onload = function(){
        // test
        ctx.drawImage(img, 0, 0, 1000, 1000);
        const imageDataTmp  = ctx.getImageData(
          0,
          0,
          ctx.canvas.width,
          ctx.canvas.height
        );

        // let dataTmp = [...imageDataTmp.data]
        let dataTmp = imageDataTmp.data.slice()

        setImageDataDataAchive(dataTmp)
        setImageData(imageDataTmp)
        // setLoaded(true)
      }
    }
  }, [
    ctx,
    props.imageInfo,
    // props.f_params
  ])

  useEffect(() => {
    if(ctx !== null && imageDataDataAchive !== null){
      // size of wrapper
      // let reLength = wrapperRef.current.offsetHeight
      let reLength
      if (height < width) {
        reLength = height
      } else {
        reLength = width
      }
      let defLength = props.imageInfo.f_params.resolution

      let cba = createColorBarArray(props.colorBarData)
      let data = imageData.data

      createImage(data, cba, imageDataDataAchive)

      ctx.putImageData(imageData, 0, 0);

      // disp resizable canvas
      const canvas2 = document.getElementById("colorImg-contents")

      canvas2.height   = reLength
      canvas2.width    = reLength

      const ctx2 = canvas2.getContext("2d")

      ctx2.drawImage(
         ctx.canvas,
         0, 0, defLength, defLength,
         0, 0, reLength , reLength
       );
    }
    setLoaded(false)
  }, [
    ctx,
    props.colorBarData,
    imageDataDataAchive,
    width,
    height,
  ])

  // useLayoutEffect(() => {
  useEffect(() => {
    // @ts-ignore
    const obs = new ResizeObserver((e) => {
      setWidth(e[0].contentRect.width)
      setHeight(e[0].contentRect.height)
    })
    obs.observe(wrapperRef.current)
    return () => obs.disconnect()

  }, [])


  return (
    <div
      className = 'colorImg-contents'
      ref       = {wrapperRef}
    >
      <canvas
        style={{display : 'none'}}
        id='canvas'
      ></canvas>
      <canvas
        onClick   = {props.onClick}
        id        = 'colorImg-contents'
      ></canvas>
    </div>
  )
}

export default ColorImg

// ------------------------------------------------------------------------

function createColorBarArray(colorBarData) {
  let colorBarArray = {
    red  : [],
    green: [],
    blue : [],
  }

  let color1 = colorBarData[0].rgb
  let color2 = colorBarData[1].rgb

  let index = colorBarData[0].colorPoint
  let posiIndex = 1

  let start = colorBarData[0].colorPoint
  let end   = colorBarData[1].colorPoint


  for(index = 0; index <= colorBarData.slice(-1)[0].colorPoint; index += 1){

    // When you reach the color point
    if(index == colorBarData[posiIndex].colorPoint && index != 255){

      color1 = colorBarData[posiIndex].rgb
      color2 = colorBarData[posiIndex + 1].rgb

      start = colorBarData[posiIndex].colorPoint
      end   = colorBarData[posiIndex + 1].colorPoint

      posiIndex += 1
    }

    colorBarArray.red[index] = Math.floor(
       1 / (end - start) * ((end - index) * color1.red + (index - start) * color2.red)
    )
    colorBarArray.green[index] = Math.floor(
       1 / (end - start) * ((end - index) * color1.green + (index - start) * color2.green)
    )
    colorBarArray.blue[index] = Math.floor(
       1 / (end - start) * ((end - index) * color1.blue + (index - start) * color2.blue)
    )
  }
  return colorBarArray
}

function createImage(imageData, colorBarArray ,achive){
  for (let i = 0; i < imageData.length; i += 4) {
    imageData[i]     = colorBarArray.red[achive[i]]  // red
    imageData[i + 1] = colorBarArray.green[achive[i + 1]]  // green
    imageData[i + 2] = colorBarArray.blue[achive[i + 2]]  // blue
    imageData[i + 3] = 255;      // alfa
  }

}
