import React, {
  // useState,
  // useContext,
} from 'react';
import {
  Outlet,
  // useNavigate
} from 'react-router-dom';
// import Loading from 'util/00_atoms/loading/loading'
// import { UserContext } from 'app'
import './emotion.scss'


const Emotion = () => {
  // const [isAxLoading, setIsLoading] = useState(false)
  // const username = useContext(UserContext)
  // console.log(username)

  return (
    // isAxLoading ? <Loading/> : (
      <div
        className={'emotion-wrapper'}
      >
      <Outlet />
      </div>
    // )
  )
}

export default React.memo(Emotion)
