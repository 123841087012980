import React, {
  useState,
  useEffect,
} from 'react';
import * as u from 'util/util'
import './cnvColorImg.scss'

// @params

const CnvColorImg = (props : {
  // grayImgSrc   : String,
  // colorBarData : Array<Object>,
  // id           : String,
  // dataIndex    : Number
  // onClick      : (void),
}) => {
  const [ctx,setCtx]              = useState(null)
  const [clrImgUrl, setClrImgUrl] = useState(null)
  const [isDrow, setIsDrow]       = useState(false)
  const canvas = document.getElementById(props.id + 'canvas')

  useEffect(() => {
    const canvas = document.getElementById(props.id + 'canvas')

    canvas.height   = 1000
    canvas.width    = 1000

    const canvasContext = canvas.getContext("2d")

    setCtx(canvasContext)
  },[])

  useEffect(() => {
    if(ctx !== null){
      const img = new Image();
      img.src = props.grayImgSrc

      img.onload = () => {
        ctx.drawImage(img, 0, 0, 1000, 1000);
        let cba = u.createColorBarArray(props.colorBarData)
        let imageData = ctx.getImageData(
          0,
          0,
          ctx.canvas.width,
          ctx.canvas.height
        );
        let dataTmp = imageData.data.slice()
        u.createImage2(imageData.data, cba, dataTmp)
        ctx.putImageData(imageData, 0, 0);
        setIsDrow(true)
      }
    }
  }, [ctx])

  useEffect(() => {
    const canvas = document.getElementById(props.id + 'canvas')
    if (isDrow === true) setClrImgUrl(canvas.toDataURL('image/png'))
  }, [isDrow])

  return (
    <>
        <canvas
          style={{
            display : 'none'
          }}
          id={props.id + 'canvas'}
        ></canvas>
        <img
          onClick    = {props.onClick}
          id         = {props.id}
          src        = {clrImgUrl}
          alt        = 'Image'
          data-index = {props.dataIndex}
        ></img>
    </>
  )
}

export default CnvColorImg
