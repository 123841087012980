import React, {useState, useEffect, useRef} from "react";
import ReactLoading from 'react-loading'
import './loading.css'

const Loading = () => {
  const loadingRef = useRef(null)
  const [loadingStyle, setLoadingStyle] = useState({})

  const handleResize = () => {
    let sW,sH
    let stlTmp = {...loadingStyle}

    if (loadingRef !== null) {
      let refCr = loadingRef.current

      sW = refCr.offsetWidth;
      sH = refCr.offsetHeight;

      if((sW / sH) <= 1){
        // longer length 'height'
        stlTmp.height = sW
        stlTmp.width  = sW
      } else {
        // longer length 'width'
        stlTmp.height = sH
        stlTmp.width  = sH
      }
      setLoadingStyle(stlTmp)
    }
  }
  
  useResizeObserver([loadingRef], handleResize);

  return (
    <div
      className='loadingWrapper'
      style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
      ref={loadingRef}
    >
      <ReactLoading type="spinningBubbles" style={loadingStyle}/>
    </div>
  )
};

export default Loading;


// treatment resize of element ---------------------------------
const useResizeObserver = (elements, callback) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries);
    });

    for (const elem of elements) {
      elem.current && resizeObserver.observe(elem.current);
    }

    return () => resizeObserver.disconnect();
  }, []);
};
