import React from 'react'

import './rgbOparate.scss'

const RGBOparate = (props) => {
  return (
    <div className='rgbOparate-wrapper'>
      <div>
        <input className='r' type="range" name="red" min="0" max="255" value={props.rgb.red} onChange={props.handleChangeColor}/>
        <input className='g' type="range" name="green" min="0" max="255" value={props.rgb.green} onChange={props.handleChangeColor}/>
        <input className='b' type="range" name="blue" min="0" max="255" value={props.rgb.blue} onChange={props.handleChangeColor}/>
      </div>
    </div>
  );
}

export default RGBOparate
