import React from 'react'
import { useIsScroll } from 'util/hooks'

import I_toTop from 'util/icons/i_toTop'

import './scrollToTop.scss'

const ScrollToTop = () => {
  const isScrolling = useIsScroll()

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div
      className={'scrollToTop-wrapper' + (isScrolling ? '' :' hidden')}
      onClick={handleClick}
      id='scrollToTop'
    >
      <I_toTop/>
    </div>
  )
}

export default ScrollToTop
