import img1 from './img/sample1.png'
import img2 from './img/sample2.jpg'
import img3 from './img/sample3.jpg'

const INTORODUCTION = {
  header : 'introduction',
  text   : `The site can use intuitively.
color, pattern, title, storys....
You can express the patterns in your brain.
In addition you can post and share, besides you can use the created image as copyright free.
`,
image : img1,
}

const INTORODUCTION_2 = {
  header : 'introduction',
  text   : `introduction
introductionintroduction
introductionintroduction
introduction
introductionintroductionintroduction
introductionintroduction
introductionintroduction
introductionintroductionintroductionintroduction
introductionintroduction
introductionintroductionintroductionintroductionintroduction
introductionintroduction
`,
image : img2,
}

const INTORODUCTION_3 = {
  header : 'introduction',
  text   : `introduction
introductionintroduction
introductionintroduction
introduction
introductionintroductionintroduction
introductionintroduction
introductionintroduction
introductionintroductionintroductionintroduction
introductionintroduction
introductionintroductionintroductionintroductionintroduction
introductionintroduction
`,
image : img3,
}

const INTORODUCTION_4 = {
  header : 'introduction',
  text   : `introduction
introductionintroduction
introductionintroduction
introduction
introductionintroductionintroduction
introductionintroduction
introductionintroduction
introductionintroductionintroductionintroduction
introductionintroduction
introductionintroductionintroductionintroductionintroduction
introductionintroduction
`,
image : img3,
}

export const ABOUT_CONTENTS = [
  INTORODUCTION,
  INTORODUCTION_2,
  INTORODUCTION_3,
  INTORODUCTION_4,
  INTORODUCTION,
  INTORODUCTION_2,
  INTORODUCTION_3,
  INTORODUCTION_4,
  INTORODUCTION,
  INTORODUCTION_2,
  INTORODUCTION_3,
  INTORODUCTION_4,
  INTORODUCTION,
  INTORODUCTION_2,
  INTORODUCTION_3,
  INTORODUCTION_4,
  INTORODUCTION,
  INTORODUCTION_2,
  INTORODUCTION_3,
  INTORODUCTION_4,
]
