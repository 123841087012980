import React, { useState, useEffect, forwardRef } from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import Axios from 'axios';

import * as u from 'util/util'
import './register.scss'
import './react-datepicker-fix.scss'

const Register = () => {
  const Today = new Date();
  const navigate = useNavigate()
  const {
    isLoading,
    getIdTokenClaims,
  } = useAuth0();
  // const [operateID, setOperateID]   = useState('')
  const [user, setUser] = useState({
    nickname: '',
    birth   : Today,
    authID  : '',
  })
  const [date, setDate] = useState(Today);

  const firstRenderFunc = async () => {
    try {
      const idToken = await getIdTokenClaims()
      let id
      if (idToken !== undefined) {
        id = idToken.sub.split('|')[1]
      } else {
        id = null
      }
      setUser({
        ...user,
        authID : id,
      })

    }
    catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(!isLoading) firstRenderFunc()
  }, [])


  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let url    = u.SERVER_URL + '/register'
    let params = {
      ...user,
      birth: user.birth.getFullYear() + "/" +  (user.birth.getMonth() + 1).toString() + "/"+ user.birth.getDate()
    }
    try {
      const res = await Axios.post(url, params)
      console.log(res)
      alert('user add success!!')
      alert('move to last location')
      // need fixing (to last location)
      navigate('/')
    } catch (error) {
      console.log(error)
      alert(error.response.data.message)
    }
  }

  return (
    <div className={'register-wrapper'}>
      <div className='contents'>
        <h1>Register page</h1>
        <form onSubmit={handleSubmit}>
          <div className='input-group'>
            <label>nickname</label>
            <input
              type="text"
              name='nickname'
              placeholder='nickname'
              required
              value={user.nickname}
              onChange={handleChange}
            />
          </div>
          <div className='input-group'>
            <label>birthday</label>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              selected={date}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={<ExampleCustomInput />}
              onChange={
                selectedDate => {
                  setDate(selectedDate || Today)
                  setUser({
                    ...user,
                    birth : (selectedDate || Today),
                  })
                }
              }
            />  
          </div>
          <div className='input-group'>
            <input 
              type={'submit'}
              value={'register'}
            ></input> 
          </div>
        </form>
      </div>
    </div>
  )
}

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <input 
    readOnly='readOnly'
    onClick={onClick} 
    ref={ref}
    value={value}
  />
));

export default Register;
