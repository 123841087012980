import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from 'app'
import * as u from 'util/util'
import Popup       from 'util/00_atoms/popup/popup'
import Separator   from 'util/00_atoms/separator/separator'
import CnvColorImg from 'util/00_atoms/cnvColorImg/cnvColorImg'
import './historyPopup.scss'

const HistoryPopup = (props) => {
  const [randomStr, setRandomStr] = useState('')
  const username = useContext(UserContext)

  useEffect(() => {
    setRandomStr(Math.random().toString(32).substring(2))
  }, [])

  return (
    <Popup
      isShown      = {props.isShown}
      popupHandler = {props.popupHandler}
    >
      <div className={'historyPopup-wrapper'}>
       <div className={'historyPopup-header'}>
         <h2>HISTORY</h2>
         <a onClick={props.pushHistory}>pushHistory</a>
       </div>
        <Separator/>
        <div className={'historyPopup-contents'}>
          {props.history.map((contents, index) => {
            return (
              <div className={'historyPopup-item'} key={'historyPopup-item' + index}>
                <h2>{index}:</h2>
                  <CnvColorImg
                    grayImgSrc = {props.gImgBase64}
                    colorBarData = {contents.colorBarData}
                    onClick={props.onClick}
                    dataIndex={index}
                    id = {'historyPopup-item' + index}
                  />
                <br/>
              </div>
            )
          })}
        </div>
      </div>
    </Popup>
  )
}

export default React.memo(HistoryPopup)
