import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'

import FlexibleText from 'util/00_atoms/flexibleText/flexibleText'

import './rgbBox.scss'

const RGBBox = (props) => {
  const [hex, setHex]           = useState('#777777');
  const [width, setWidth]       = useState(100)
  const [height, setHeight]     = useState(100)
  const [addClass, setAddClass] = useState('')

  // const [style, setStyle]  = useState()

  const ref = useRef(null)

  useEffect(() => {
    let rgbcopy = {...props.rgb}
    let aveRGB = (rgbcopy.red + rgbcopy.blue + rgbcopy.green) / 3
    if (aveRGB <= 50) {
      setAddClass('dark')
    } else {
      setAddClass('')
    }

    const code = calculateRGB(rgbcopy);

    setHex(code);
    // setStyle(styleTmp)
  }, [props])


  useLayoutEffect(() => {
    // @ts-ignore
    const obs = new ResizeObserver((e) => {
      let w = e[0].target.parentElement.offsetWidth
      let h = e[0].target.parentElement.offsetHeight
      let baseLength
      if (w < h) {
        baseLength = w
      } else {
        baseLength = h
      }
      setWidth(baseLength)
      setHeight(baseLength)
    })
    obs.observe(ref.current)
    return () => obs.disconnect()

  }, [])

  return (
    <div
      className={'rgbBox-wrapper ' + addClass}
      style={{
        backgroundColor : hex,
        height          : height,
        width           : width,
      }}
      ref={ref}
    >
        <FlexibleText>{hex}</FlexibleText>
    </div>
  )
}

export default RGBBox

// culculate HEX ---------------------------------

function calculateRGB(rgb){
  const r = Number(rgb.red);
  const g = Number(rgb.green);
  const b = Number(rgb.blue);
  let color_code;

  color_code = '#'
              + ('0' + r.toString(16)).slice( -2 )
              + ('0' + g.toString(16)).slice( -2 )
              + ('0' + b.toString(16)).slice( -2 );
  return color_code;
}
