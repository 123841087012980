import React     from 'react'
import Popup     from 'util/00_atoms/popup/popup'
import Separator from 'util/00_atoms/separator/separator'
import './imgInfoPopup.scss'

const ImgInfoPopup = (props) => {
  return (
    <Popup
      isShown      = {props.isShown}
      popupHandler = {props.popupHandler}
    >
      <Description
        imageInfo={props.imageInfo}
      />
    </Popup>
  )
}

const Description = (props) => {
  return (
    <div className={'imgInfoPopup-wrapper'}>
    <h2>TITLE</h2>
    <Separator/>
    <p>{props.imageInfo.title}</p><br/><br/>
    <h2>X</h2>
    <Separator/>
    <p>{props.imageInfo.f_params.w}</p><br/><br/>
    <h2>Y</h2>
    <Separator/>
    <p>{props.imageInfo.f_params.h}</p><br/><br/>
    <h2>MAGUNIFICATION</h2>
    <Separator/>
    <p>{props.imageInfo.f_params.maguni}</p><br/><br/>
    <h2>STEP</h2>
    <Separator/>
    <p>{props.imageInfo.f_params.repetition}</p><br/><br/>
    <h2>CREATED BY</h2>
    <Separator/>
    <p>{props.imageInfo.owner}</p><br/><br/>
    <h2>DESCRIPTION</h2>
    <Separator/>
    {convertIndent(props.imageInfo.description)}<br/><br/>
    </div>
  )
}

export default ImgInfoPopup


// convert indent
function convertIndent(string) {
  let s = null
  if (typeof(string) === 'string') {
    s = string.split('\n').map((t, index) => t ? (<p key={t + index}>{t}</p>) : <br/>)
  }
  return s
}
