import React, { useEffect, useState, useRef } from 'react'
import './button.scss'

import FlexibleText from 'util/00_atoms/flexibleText/flexibleText'

const Button = (props) => {
  // Button component
  // const [style, setStyle] = useState({})
  return (
    <div className='button-wrapper'>
      <a id={props.id} onClick={props.onClick}>
        <FlexibleText>{props.children}</FlexibleText>
      </a>
    </div>
  )
}

export default Button;
