import React, {
  useEffect,
  useState,
  useCallback
} from 'react'
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Axios from 'axios';
import * as u from 'util/util'
import FlexibleText     from 'util/00_atoms/flexibleText/flexibleText'
import Loading          from 'util/00_atoms/loading/loading'
import DescriptionPopup from 'util/01_molecules/descriptionPopup/descriptionPopup'
import WritePopup       from 'util/01_molecules/writePopup/writePopup'
import ImgInfoPopup     from 'util/01_molecules/imgInfoPopup/imgInfoPopup'
import ImagePopup       from 'util/01_molecules/imagePopup/imagePopup'
import './info.scss'
import 'react-lazy-load-image-component/src/effects/blur.css';

import I_info   from 'util/icons/i_info'
import I_edit   from 'util/icons/i_edit'
import I_write  from 'util/icons/i_write'
import I_trash  from 'util/icons/i_trash'

const Info = (props) => {
  // const [info, setInfo]                 = useState({imageInfo:{url:''}})
  const [imageInfo, setImageInfo]       = useState({...props.imageInfo})
  const [isPopup, setIsPopup]           = useState(false)
  const [isWritePopup, setIsWritePopup] = useState(false)
  const [isInfoPopup, setIsInfoPopup]   = useState(false)
  const [isUserOwn, setIsUserOwn]       = useState(false)
  const [isImagePop, setIsImagePop]     = useState(false)

  const {
    isAuthenticated,
    withAuthenticationRequired,
    isLoading,
    getIdTokenClaims,
  } = useAuth0();


  // Receive value from previous page
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  // const param_id = searchParams.get("id");
  let param_id = searchParams.get("id");


  const deleteInfo = async () => {

    let resdata
    let infoUrl = u.SERVER_URL + '/info?id=' + imageInfo.img_id
    let imageUrl = u.FILE_SERVER_URL + '/image?id=' + imageInfo.img_id
    let flag = window.confirm('Confirmation message')

    if (flag) {
      // delete DB data
      resdata = await Axios.delete(infoUrl)

      // delete image data
      if(resdata.data.result) {
        resdata = await Axios.delete(imageUrl);
      }
      navigate('/')
    }
  }

  const putInfo = async (e) => {
    let url = u.SERVER_URL + '/imageInfo'
    let iiTmp = {...imageInfo}
    iiTmp.title       = e.target.title.value
    iiTmp.description = e.target.description.value

    const res = await Axios.put(url, iiTmp)
    console.log(res)
    window.location.reload()
  }

  const onClickColor = () => {
    navigate('/color', {state: {
      // info          : info,
      parent_id    : imageInfo.img_id,
      operateType  : imageInfo.operateType,
      f_params     : imageInfo.f_params,
      colorBarData : imageInfo.colorBarData,
      from         : 'emotions'
    }})
  }

  const popupHandler = (e) => {
    e.stopPropagation()
    let id = e.currentTarget.id

    if(id === 'popup-back') {
      setIsPopup(false)
      setIsWritePopup(false)
      setIsInfoPopup(false)
      setIsImagePop(false)
    } else if(id ==='image-title') {
      setIsPopup(true)
    } else if(id === 'write') {
      setIsWritePopup(true)
    } else if(id === 'info') {
      setIsInfoPopup(true)
    } else if(id === 'dispImg') {
      setIsImagePop(true)
    }
  }

  // let { id } = useParams();
  const firstRenderAction = async () => {
    try {
      let iiTmp = {...props.imageInfo}

      if (props.operateID === iiTmp.owner) {
        setIsUserOwn(true)
      }
      setImageInfo(iiTmp)
    }
    catch(error) {
      console.error(error)
    }
  }
  // let params = useParams();

  useEffect(() => {
    if (param_id === null) {
      if (props.id !== null) {
        param_id = props.id
      }
      else {
        navigate('/')
      }
    }
    firstRenderAction()
  }, [isLoading])

  return (
    <div className='info-wrapper'>
      {
        isLoading ? <Loading /> :
        <>
        <DispImg
          img_id       = {imageInfo.img_id}
          popupHandler = {popupHandler}

        />
        <Descrive
          // info                       = {info}
          // f_params                   = {f_params}
          imageInfo                  = {imageInfo}
          deleteInfo                 = {deleteInfo}
          onClickColor               = {onClickColor}
          idLoading                  = {isLoading}
          isAuthenticated            = {isAuthenticated}
          withAuthenticationRequired = {withAuthenticationRequired}
          isUserOwn                  = {isUserOwn}
          popupHandler               = {popupHandler}
          navigate                   = {navigate}
        />
        <DescriptionPopup
          isShown      = {isPopup}
          popupHandler = {popupHandler}
          description  = {imageInfo.description}
          title        = {imageInfo.title}
        />
        <ImgInfoPopup
          isShown      = {isInfoPopup}
          popupHandler = {popupHandler}
          imageInfo    = {imageInfo}
        />
        <WritePopup
         isShown          = {isWritePopup}
         popupHandler     = {popupHandler}
         description      = {imageInfo.description}
         title            = {imageInfo.title}
         handleInfoSubmit = {putInfo}
        />
        <ImagePopup
          img_id       = {imageInfo.img_id}
          isShown      = {isImagePop}
          popupHandler = {popupHandler}
        />
        </>
      }
    </div>
  )
}

const DispImg = (props) => {
  const [isLoaded, setIsloaded] = useState(false)
  const loadingClassname = 'imageLoading'
  return (
    <div className='dispImg-wrapper'>
        <LazyLoadImage
          src={u.FILE_SERVER_URL + '/image?id=' + props.img_id}
          alt='Image'
          id='dispImg'
          onClick={props.popupHandler}
          placeholderSrc={u.FILE_SERVER_URL + '/image?id=' + props.img_id + '&load=true'}
        />
    </div>
  )
}

const Descrive = (props) => {
  let i = {...props.imageInfo}

  const buttonDispHandler = useCallback((component, isAuth, isUser) => {
    if (isAuth) {
      if (isUser) {
        return component
      } else {
        return null
      }
    } else {
      return null
    }
  })

  const renderIsOrigin = () => {
    if (i.parent_id !== null && i.parent_id !== '') {
      return (
        <a　
          onClick={() => {
            props.navigate('/emotion/' + i.parent_id)
            window.location.reload()
          }}
        >INSPIRED BY...</a>
      )
    } else {
      return <div>★★★ORIGINE★★★</div>
    }
  }

  return (
    <div className='descrive-wrapper'>
      <div className='neumo image-title' onClick={props.popupHandler} id={'image-title'}>
        <FlexibleText>
          {i.title}
        </FlexibleText>
      </div>
      <div className={'relation-wrapper'}>
        <div className={'owner-wrapper'}>
          <a
            onClick={() => props.navigate('/' + i.owner)}
          >CREATED BY : {i.owner}</a>
        </div>
        <div className={'parent-wrapper'}>
          {renderIsOrigin()}
        </div>
        <div className={'child-wrapper'}>
          <a
            onClick={() => props.navigate('/children/' + i.img_id)}
          >children</a>
        </div>
      </div>
      <div className='detail-wrapper'>
        <div className={'icons-wrapper'}>
          <div className={'icon-wrapper'} id={'info'} onClick={props.popupHandler}><I_info /></div>
          <div className={'icon-wrapper'} id={'edit'} onClick={props.onClickColor}><I_edit /></div>
          {buttonDispHandler(
            <>
            <div className={'icon-wrapper'} id={'write'} onClick={props.popupHandler}><I_write /></div>
            <div className={'icon-wrapper'} id={'trash'} onClick={props.deleteInfo}><I_trash /></div>
            </>,
            props.isAuthenticated,
            props.isUserOwn
          )}
        </div>
      </div>
    </div>
  )
}

// export default React.memo(Info);
export default React.memo(Info);


// convert indent
function convertIndent(string) {
  let s = null
  if (typeof(string) === 'string') {
    s = string.split('\n').map((t) => t ? (<p key={t}>{t}</p>) : <br/>)
  }
  return s
}
