import React, {
  useState,
  useEffect,
  useContext,
  // useCallback,
  // useRef,
  // useLayoutEffect,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  // useSearchParams
} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import InfiniteScroll  from "react-infinite-scroller"
import Axios from 'axios';

// import Info from 'info/info'
import { UserContext } from 'app'
import Info from 'util/02_organisms/info/info'
import * as u from 'util/util'
import { useUserId } from 'util/hooks'
import Loading from 'util/00_atoms/loading/loading'
import './emotions.scss'

import ScrollToTop from 'util/00_atoms/scrollToTop/scrollToTop'

const Emotions = () => {
  const username = useContext(UserContext)
  const [imageInfos, setImageInfos] = useState([])
  const [isAxLoading, setIsLoading] = useState(true)
  const [activeID, setActiveID]     = useState('')
  const [hasMore, setHasMore] = useState(true)

  const {
    isLoading,
  } = useAuth0();

  const params = useParams();
  const navigate = useNavigate()
  const location = useLocation()

  const firstRenderFunc = async () => {
    try {
      let data
      let ii_tmp = []
      setIsLoading(true)
      const res1 = await Axios.get(u.SERVER_URL + '/imageInfo?img_id=' + params.id)
      data = {...res1.data}
      ii_tmp.push(data)

      const res2 = await Axios.get(u.SERVER_URL + '/imageInfo?rand=true')
      ii_tmp.push(res2.data)

      setImageInfos(ii_tmp)
    }
    catch(error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if(imageInfos.length !== 0) {
      // ======== test =========================================
      // 着火点となる要素
      const headings = document.querySelectorAll('.emotions-contents');

      const options = {
        threshold: 1
      };

      // 実行するよ
      const observer = new IntersectionObserver(showElements, options);

      // 各 .heading に到達したら発動。複数あるから forEach 使うよ。
      headings.forEach(heading => {
        observer.observe(heading);
      });

      // 要素が表示されたら実行する動作
      function showElements(entries){
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 各 .heading に .active を加える
            entry.target.classList.add('active');
            // console.log(entry.target.id)
            setActiveID(entry.target.id)
            // window.location.hash = entry.target.id;
          }
        });
      };
    }
  })

  useEffect(() => {
    if(activeID !== '') {
      navigate('/emotion/' + activeID , { replace: true })
    }
  }, [activeID])

  useEffect(() => {
      firstRenderFunc()
}, [])

  const loadMore = async (page) => {
    console.log(page + ' : readed function "loadMore"')
    try {
      let data1
      let imageInfosTmp = [...imageInfos]
      const re = await Axios.get(u.SERVER_URL + '/imageInfo?rand=true')
      imageInfosTmp.push(re.data)

      const res = await Axios.get(u.SERVER_URL + '/imageInfo?rand=true')
      imageInfosTmp.push(res.data)
      setImageInfos(imageInfosTmp)
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

const loader =<div className="loader" key={0}>Loading ...</div>;

  return (
    isAxLoading ? <Loading/> : (
      <div
        className={'emotions-wrapper'}
        // onScroll={handleScroll}
      >
      <ScrollToTop/>
      <InfiniteScroll
        loadMore={loadMore}    //項目を読み込む際に処理するコールバック関数
        hasMore={hasMore}         //読み込みを行うかどうかの判定
        threshold={250}
        useWindow={false}
      >
      {
        imageInfos.map((contents, index) =>
          <div
            key={contents.img_id + index}
            id={contents.img_id}
            className={'emotions-contents'}
          >
            <Info
              imageInfo  = {contents}
              operateID = {username}
            />
          </div>
        )
      }
      </InfiniteScroll>

    </div>
    )
  )
}

export default Emotions
