import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from "@auth0/auth0-react";
import App from './app';
import './all.scss'
import './reset.css'
import * as u from 'util/util'

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={u.AUTH0_DOMAIN}
    clientId={u.AUTH0_CLIENT_ID}
    redirectUri={u.AUTH0_LOGIN_REDIRECT_URI}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>,
);
