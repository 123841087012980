import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import './colorBar.scss'


// params : colorBarData
// params : activeIndex
const ColorBar = (props) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [isColorBarRef, setIsColorBarRef] = useState(false)
  const wrp_ref = useRef(null)

  const renderColorBars = () => {
    let ref = wrp_ref.current

    let colorList = []
    let i
    let colorCode1, colorCode2 = ''
    let cbd = [...props.colorBarData]
    // let heightAll = ref.offsetHeight
    let heightAll = height
    let heightTmp
    let styleTmp = {}

    let activeClassName = ''

    // styleTmp.width      = ref.offsetWidth
    // styleTmp.width      = 100

    for (i = 0; i < cbd.length - 1 ; i += 1) {
      heightTmp = heightAll * ((cbd[i + 1].colorPoint - cbd[i].colorPoint) / 255)

      colorCode1 = convertToColorcode(cbd[i].rgb)

      colorCode2 = convertToColorcode(cbd[i + 1].rgb)

      styleTmp = {...styleTmp}

      styleTmp.background = (
        'linear-gradient(to bottom, ' + colorCode1 + ', ' + colorCode2 + ')'
      )
      styleTmp.height     = heightTmp
      if(ref !== null) {
        // styleTmp.width      = ref.offsetWidth
        styleTmp.width      = width
      }

      if(props.activeIndex === i) {
        activeClassName = 'active'
      } else if (props.activeIndex === cbd.length - 1 && i === cbd.length - 2) {
        activeClassName = 'active-end'
      } else {
        activeClassName = ''
      }

      colorList.push(
        <div className={activeClassName} key={i} id={'colorBar-' + i} style={styleTmp} ></div>
      )
    }
    return colorList
  }

  useEffect(() => {
    if(wrp_ref !== null) {
      setIsColorBarRef(true)
    } else {
      setIsColorBarRef(false)
    }
  })


  useLayoutEffect(() => {
    // @ts-ignore
    const obs = new ResizeObserver((e) => {
      setWidth(e[0].contentRect.width)
      setHeight(e[0].contentRect.height)
    })
    obs.observe(wrp_ref.current)
    return () => obs.disconnect()

  }, [])


  return (
    <div
      className={'colorBar-wrapper'}
      ref={wrp_ref}
    >
      {isColorBarRef ? renderColorBars() : null }
      <Cursol activeIndex={props.activeIndex}
/>
    </div>
  )
}

// params : activeIndex
const Cursol = (props) => {
  return (
    <div className={'cursol-wrapper'}>

    </div>
  )
}

export default ColorBar


function convertToColorcode(rgb){
  const r = Number(rgb.red);
  const g = Number(rgb.green);
  const b = Number(rgb.blue);
  let color_code;

  color_code = (
    '#'
    + ('0' + r.toString(16)).slice( -2 )
    + ('0' + g.toString(16)).slice( -2 )
    + ('0' + b.toString(16)).slice( -2 )
  )
  return color_code;
}
