import React, {
  useEffect,
  useState,
  // useRef,
  // useCallback
} from 'react'
import {
  // useLocation,
  useNavigate,
  useParams,
  // useSearchParams
} from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth0 } from "@auth0/auth0-react";
import Axios from 'axios';
import * as u from 'util/util'
import './user.scss'


const User = () => {
  const [userInfo, setUserInfo] = useState({})
  const [axiosLoading, setAxiosLoading] = useState(true)
  const navigate = useNavigate()

  const {
    user,
    getIdTokenClaims,
    isLoading
  } = useAuth0();

  const params = useParams();
  // let param_id = searchParams.get("id");

  const firstRenderAction = async () => {
    if (!isLoading) {
      setAxiosLoading(true)
      try {
        const res1 = await Axios.get(u.SERVER_URL + '/userInfo?id=' + params.id)

        setUserInfo(res1.data)
      }
      catch(error) {
        alert(error.response.data.message)
        alert('MOVE TO TOP PAGE')
        navigate('/')
      }
      setAxiosLoading(false)
    }
  }

  useEffect(() => {
    if (params.id !== null) {
      firstRenderAction()
    }else {
      alert('not found USER ID!!!!!')
    }
  }, [isLoading])



  const renderImgs = () => {
    let images
    let url = ''
    url += u.SERVER_URL + '/image?id='
    // url += '?id=' + contents.img_id
    if ('images' in userInfo) {
      images = [...userInfo.images]
    } else {
      return
    }

    return (
      images.map((contents, index) => {
        return (
          <div className={'user-img-items'} key={contents.img_id + index}>
          {
            // <img
            //   onClick={() => navigate('/emotion/' + contents.img_id)}
            //   src={url + contents.img_id}
            // ></img>
          }
          {
            <LazyLoadImage
              alt='Image'
              onClick={() => navigate('/emotion/' + contents.img_id)}
              src={u.FILE_SERVER_URL + '/image?id=' + contents.img_id}
              placeholderSrc={u.FILE_SERVER_URL + '/image?id=' + contents.img_id + '&load=true'}
            ></LazyLoadImage>
          }
          </div>
        )
      })
    )
  }

  return (

    <div className={'user-wrapper'}>
    {axiosLoading ? null :
      <>
      <div className={'user-header'}>
        <div className={'user-icon'}>
          <img src={null}/>
        </div>
        <div className={'user-description'}>{userInfo.user_id}</div>

      </div>
      <div className={'user-contents'}>
        {renderImgs()}
      </div>
      </>
    }
    </div>
  )
}

export default User;
