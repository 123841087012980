import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import Axios from 'axios';

import * as u from 'util/util'
import Loading    from 'util/00_atoms/loading/loading'

import './authenticate.scss'

const PostAuthenticate = (e) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { user, getIdTokenClaims } = useAuth0();
    const params = useParams()

    console.log(params)

    const firstRenderFunc = async () => {
      let authid = user.sub.split('|')[1]
      const authRes = await Axios.get(u.SERVER_URL + '/auth?authid=' + authid)
      if (authRes.data.id == 'ANONYMOUS') {
        navigate('/register')

      } else {
        navigate('/')
      }
    }


    useEffect(() => {
      firstRenderFunc()
    }, [])

    return (
        <div className='authenticate-wrapper'>
            <Loading/>
        </div>
    )
}

export default PostAuthenticate
